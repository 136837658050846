












































































































































































































import type { PropType } from 'vue';
import { mapState } from 'vuex';

import Tooltip from '@/components/tooltip/Tooltip.vue';
import BaseDialog from '@/components/BaseDialog.vue';
import IdsListManage from '@/components/IdsListManage.vue';
import difference from 'lodash/difference';
import { formatFeatureName } from '@/helpers/featureFlagHelpers';
import { textOverflowMiddle } from '@/helpers/formatString';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  abTestManageFeatureFlag,
  deleteManageOperatorFeatureFlag,
  manageOperatorFeatureFlag
} from '@/api/FeatureFlags';
import type { AxiosError } from 'axios';
import {
  ArrayItem,
  EFeatureFlagListType,
  FeatureFlagABTestParams,
  ILoyaltyFeatureFlagItem,
  ICopyStakeFeatureFlagItem
} from '@/api/schema';
import {
  addUserIdsFeatureFlag,
  deleteUserIdsFeatureFlag,
  updateAnonymousRegularUserStateFeatureFlag
} from '@/api/CopyStake/FeatureFlags';

const ETabKeys = {
  ALL_USERS: 'ALL_USERS',
  AFFILIATE_LIST: 'AFFILIATE_LIST',
  AB_TEST: 'AB_TEST'
};

export default {
  name: 'FeatureFlagManageDialog',
  components: {
    IdsListManage,
    Tooltip,
    BaseDialog
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    featureData: {
      type: Object as
        | PropType<ILoyaltyFeatureFlagItem>
        | PropType<ICopyStakeFeatureFlagItem>,
      default: (): ILoyaltyFeatureFlagItem | ICopyStakeFeatureFlagItem => {
        return {
          operatorUserIds: [],
          partnerIds: [],
          listType: EFeatureFlagListType.WHITE_LIST,
          random: null,
          percentage: null
        } as ILoyaltyFeatureFlagItem | ICopyStakeFeatureFlagItem;
      }
    },
    isCopyStakeFeatureFlag: {
      type: Boolean,
      default: (): boolean => false
    },
    isLoadingFeature: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  filters: {
    formatFeatureName
  },
  data: (): any => ({
    EFeatureFlagListType,
    ETabKeys,
    textOverflowMiddle,

    tabsKeyValue: ETabKeys.ALL_USERS,

    switchGroupUserTypeValue: {
      regularUser: false,
      anonymousUser: false
    },

    radioGroupAllUsersValue: EFeatureFlagListType.ALL_USERS,
    usersIdsWhiteList: [],
    usersIdsBlackList: [],

    radioGroupAffiliateListValue: EFeatureFlagListType.AFFILIATE_ALL_USERS,
    affiliateIdsWhiteList: [],
    affiliateIdsBlackList: [],

    randomIsEnabledValue: false,
    randomPercentageValue: null,
    percentageValues: [25, 50, 75],

    loading: {
      manageIds: false,
      abTest: false
    },

    abTestListHeaders: [
      {
        text: 'OPERATOR USER ID',
        value: 'operatorUserId'
      }
    ]
  }),
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    featureFlagId(): number {
      return this.isCopyStakeFeatureFlag
        ? this.featureData.id
        : this.featureData.featureFlagId;
    },
    tabsParams(): ArrayItem[] {
      return [
        {
          text: 'All Users',
          value: ETabKeys.ALL_USERS
        },
        {
          text: 'Affiliate List',
          value: ETabKeys.AFFILIATE_LIST,
          disabled: this.isCopyStakeFeatureFlag
        },
        {
          text: 'A/B Test',
          value: ETabKeys.AB_TEST,
          disabled: true // DEV-6917 hide 'A/B test' tab
        }
      ].filter((tab) => !tab.disabled);
    },
    dialogTitle(): string {
      return `Manage Users “${this.$options.filters.formatFeatureName(
        this.featureData.name
      )}”`;
    },
    showDialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    idsTestList(): { operatorUserId: string }[] {
      return (
        this.featureData?.operatorUserIds?.map((v) => ({
          operatorUserId: v
        })) || []
      );
    },
    isDisabledSaveBtn(): boolean {
      if (this.isLoadingFeature || this.loading.manageIds) return true;

      switch (this.tabsKeyValue) {
        case ETabKeys.ALL_USERS:
          return (
            this.radioGroupAllUsersValue !== EFeatureFlagListType.ALL_USERS &&
            (this.radioGroupAllUsersValue === EFeatureFlagListType.WHITE_LIST
              ? !this.usersIdsWhiteList.length
              : !this.usersIdsBlackList.length)
          );
        case ETabKeys.AFFILIATE_LIST:
          return (
            this.radioGroupAffiliateListValue !==
              EFeatureFlagListType.AFFILIATE_ALL_USERS &&
            (this.radioGroupAffiliateListValue ===
            EFeatureFlagListType.AFFILIATE_WHITE_LIST
              ? !this.affiliateIdsWhiteList.length
              : !this.affiliateIdsBlackList.length)
          );
        case ETabKeys.AB_TEST:
          return this.loading.abTest;
        default:
          return false;
      }
    }
  },
  watch: {
    featureData: {
      handler(
        feature: ILoyaltyFeatureFlagItem | ICopyStakeFeatureFlagItem
      ): void {
        if (feature) {
          this.setFeatureFlagData();
        }
      },
      deep: true
    }
  },
  methods: {
    setFeatureFlagData(): void {
      this.switchGroupUserTypeValue = {
        regularUser: this.featureData.regularUser || false,
        anonymousUser: this.featureData.anonymousUser || false
      };

      this.usersIdsWhiteList = [];
      this.usersIdsBlackList = [];
      this.affiliateIdsWhiteList = [];
      this.affiliateIdsBlackList = [];

      this.randomIsEnabledValue = this.featureData.random;
      this.randomPercentageValue = this.featureData.percentage || 25;

      if (this.tabsKeyValue === ETabKeys.AB_TEST && this.showDialog) return;

      const listTypeConfig: { [key: string]: any } = {
        [EFeatureFlagListType.ALL_USERS]: {
          tabKey: ETabKeys.ALL_USERS,
          radioGroupValue: 'radioGroupAllUsersValue'
        },
        [EFeatureFlagListType.WHITE_LIST]: {
          tabKey: ETabKeys.ALL_USERS,
          radioGroupValue: 'radioGroupAllUsersValue',
          updateIdsList: () =>
            (this.usersIdsWhiteList = this.featureData.operatorUserIds)
        },
        [EFeatureFlagListType.BLACK_LIST]: {
          tabKey: ETabKeys.ALL_USERS,
          radioGroupValue: 'radioGroupAllUsersValue',
          updateIdsList: () =>
            (this.usersIdsBlackList = this.featureData.operatorUserIds)
        },
        [EFeatureFlagListType.AFFILIATE_ALL_USERS]: {
          tabKey: ETabKeys.AFFILIATE_LIST,
          radioGroupValue: 'radioGroupAffiliateListValue'
        },
        [EFeatureFlagListType.AFFILIATE_WHITE_LIST]: {
          tabKey: ETabKeys.AFFILIATE_LIST,
          radioGroupValue: 'radioGroupAffiliateListValue',
          updateIdsList: () =>
            (this.affiliateIdsWhiteList = this.featureData.partnerIds)
        },
        [EFeatureFlagListType.AFFILIATE_BLACK_LIST]: {
          tabKey: ETabKeys.AFFILIATE_LIST,
          radioGroupValue: 'radioGroupAffiliateListValue',
          updateIdsList: () =>
            (this.affiliateIdsBlackList = this.featureData.partnerIds)
        }
      };

      const listTypeKey: EFeatureFlagListType = this.featureData.listType;

      const config =
        listTypeConfig[listTypeKey] ||
        listTypeConfig[EFeatureFlagListType.ALL_USERS];

      this.tabsKeyValue = config.tabKey;
      this[config.radioGroupValue] = listTypeKey || ETabKeys.ALL_USERS;
      if (config.updateIdsList) {
        config.updateIdsList();
      }
    },
    updateUsersIdsList(ids: string[]): void {
      const mapKeys = {
        [EFeatureFlagListType.WHITE_LIST]: 'usersIdsWhiteList',
        [EFeatureFlagListType.BLACK_LIST]: 'usersIdsBlackList'
      };

      this[mapKeys[this.radioGroupAllUsersValue]] = ids;
    },
    updateAffiliateIdsList(ids: string[]): void {
      const mapKeys = {
        [EFeatureFlagListType.AFFILIATE_WHITE_LIST]: 'affiliateIdsWhiteList',
        [EFeatureFlagListType.AFFILIATE_BLACK_LIST]: 'affiliateIdsBlackList'
      };

      this[mapKeys[this.radioGroupAffiliateListValue]] = ids;
    },
    saveUsersHandler(): void {
      if (this.radioGroupAllUsersValue === EFeatureFlagListType.ALL_USERS) {
        this.updateFeatureFlagManageParams(this.radioGroupAllUsersValue);
        return;
      }

      const mapIdsListKeys = {
        [EFeatureFlagListType.WHITE_LIST]: this.usersIdsWhiteList,
        [EFeatureFlagListType.BLACK_LIST]: this.usersIdsBlackList
      };

      const newList = mapIdsListKeys[this.radioGroupAllUsersValue];

      const isSameListType =
        this.featureData.listType === this.radioGroupAllUsersValue;

      const addedIdsList: string[] = isSameListType
        ? difference(newList, this.featureData.operatorUserIds)
        : newList;
      const deletedIdsList: string[] = isSameListType
        ? difference(this.featureData.operatorUserIds, newList)
        : [];

      this.updateFeatureFlagManageParams(this.radioGroupAllUsersValue, {
        ...(addedIdsList.length ? { added: addedIdsList } : {}),
        ...(deletedIdsList.length ? { removed: deletedIdsList } : {})
      });
    },
    saveAffiliatesHandler(): void {
      if (
        this.radioGroupAffiliateListValue ===
        EFeatureFlagListType.AFFILIATE_ALL_USERS
      ) {
        this.updateFeatureFlagManageParams(this.radioGroupAffiliateListValue);
        return;
      }

      const mapIdsListKeys = {
        [EFeatureFlagListType.AFFILIATE_WHITE_LIST]:
          this.affiliateIdsWhiteList,
        [EFeatureFlagListType.AFFILIATE_BLACK_LIST]:
          this.affiliateIdsBlackList
      };

      const newList = mapIdsListKeys[this.radioGroupAffiliateListValue];

      const isSameListType =
        this.featureData.listType === this.radioGroupAffiliateListValue;

      const addedIdsList: string[] = isSameListType
        ? difference(newList, this.featureData.partnerIds)
        : newList;
      const deletedIdsList: string[] = isSameListType
        ? difference(this.featureData.partnerIds, newList)
        : [];

      this.updateFeatureFlagManageParams(this.radioGroupAffiliateListValue, {
        ...(addedIdsList.length ? { added: addedIdsList } : {}),
        ...(deletedIdsList.length ? { removed: deletedIdsList } : {})
      });
    },
    saveABTestHandler(): void {
      this.loading.abTest = true;

      const payload: FeatureFlagABTestParams = {
        enable: this.randomIsEnabledValue,
        percentage: this.randomPercentageValue
      };

      abTestManageFeatureFlag(this.featureFlagId, payload)
        .then((): void => {
          this.$toast.success(
            `A/B test for feature “${this.$options.filters.formatFeatureName(
              this.featureData.name
            )}” has been ${
              this.randomIsEnabledValue ? 'launched' : 'turned off'
            }`
          );
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading.abTest = false;

          this.$emit('submit');
        });
    },
    updateFeatureFlagManageParams(
      listType: EFeatureFlagListType,
      idsList?: {
        added?: string[];
        removed?: string[];
      }
    ): void {
      this.loading.manageIds = true;
      const promises = [];

      const mapListIdsKey = {
        [ETabKeys.ALL_USERS]: 'operatorUserIds',
        [ETabKeys.AFFILIATE_LIST]: 'partnerIds'
      };

      const listKey = mapListIdsKey[this.tabsKeyValue];

      if (
        listType === EFeatureFlagListType.ALL_USERS ||
        listType === EFeatureFlagListType.AFFILIATE_ALL_USERS ||
        idsList?.added
      ) {
        const addPayload = {
          listType,
          ...(idsList?.added ? { [listKey]: idsList.added } : {})
        };
        const addIdsRequest = this.isCopyStakeFeatureFlag
          ? addUserIdsFeatureFlag
          : manageOperatorFeatureFlag;

        promises.push(addIdsRequest(this.featureFlagId, addPayload));
      }

      if (idsList?.removed) {
        const removePayload = {
          listType,
          [listKey]: idsList.removed
        };
        const removeIdsRequest = this.isCopyStakeFeatureFlag
          ? deleteUserIdsFeatureFlag
          : deleteManageOperatorFeatureFlag;

        promises.push(removeIdsRequest(this.featureFlagId, removePayload));
      }

      if (this.isCopyStakeFeatureFlag && this.isSuperAdmin) {
        if (
          this.featureData.regularUser !==
            this.switchGroupUserTypeValue.regularUser ||
          this.featureData.anonymousUser !==
            this.switchGroupUserTypeValue.anonymousUser
        ) {
          const payloadData = {
            regularUser: this.switchGroupUserTypeValue.regularUser,
            anonymousUser: this.switchGroupUserTypeValue.anonymousUser
          };

          promises.push(
            updateAnonymousRegularUserStateFeatureFlag(
              this.featureFlagId,
              payloadData
            )
          );
        }
      }

      Promise.all(promises)
        .then((): void => {
          this.$toast.success(
            `Feature “${this.$options.filters.formatFeatureName(
              this.featureData.name
            )}” has been updated`
          );
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally(() => {
          this.$emit('submit');
          this.loading.manageIds = false;
        });
    },
    closeAction(): void {
      this.showDialog = false;

      this.$nextTick(() => {
        this.setFeatureFlagData();
      });
    }
  }
};
